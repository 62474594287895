import CheckBox from './CheckBox'
import RadioBtnBar from './RadioBtnBar'
import AlertsCheckbox from './AlertsCheckbox'
import FileUploader from './FileUploader'
import FileDemonstration from './FileDemonstration'

export default function install(Vue) {
  Vue.component(CheckBox.name, CheckBox)
  Vue.component(RadioBtnBar.name, RadioBtnBar)
  Vue.component(AlertsCheckbox.name, AlertsCheckbox)
  Vue.component(FileUploader.name, FileUploader)
  Vue.component(FileDemonstration.name, FileDemonstration)
}
