import VueTrans, { compileMessages } from '@aspectus/vue-trans'

const NUMERIC = 'numeric';
const LONG = 'long';
const DATE_CONFIG = {
  year: NUMERIC,
  month: NUMERIC,
  day: NUMERIC,
};
const SHORT_DATE_CONFIG = {
  ...DATE_CONFIG,
  year: '2-digit',
};
const TIME_CONFIG = {
  hour: NUMERIC,
  minute: NUMERIC,
};
const DATETIME_CONFIG = {
  ...DATE_CONFIG,
  ...TIME_CONFIG,
};

const dateTimeFormats = {
  [window.language]: {
    shortDate: SHORT_DATE_CONFIG,
    date: DATE_CONFIG,
    time: TIME_CONFIG,
    month: { year: NUMERIC, month: LONG },
    datetime: DATETIME_CONFIG,
  },
}

const SHORT = { notation: 'standard', compactDisplay: 'long' }
const LONG_NUMBER = { notation: 'engineering' }
const numberFormats = {
  [window.language]: {
    // liter: u('liter'),
    short: SHORT,
    long: LONG_NUMBER,
    currency: {
      style: 'currency',
      currency: 'USD',
      currencyDisplay: 'symbol',
      signDisplay: 'never',
    },
    crypto: {
      thousandsSeparator: ',',
      decimalSeparator: '.',
    },

    // kilogram: u('kilogram'),
    // degree: u('degree'),
    // kilometer: u('kilometer'),
  },
  any: {
    crypto: {
      thousandsSeparator: ',',
      decimalSeparator: '.',
    },
  },
}
export function createI18n({ Vue, I18n }) {
  Vue.use(VueTrans)

  const i18n = new I18n({
    ...compileMessages(),
    silentTranslationWarn: true,
    dateTimeFormats,
    numberFormats,
  })

  return i18n
}
