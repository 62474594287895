import { createI18n } from '@/i18n'
import router from './js/routers'
import store from './js/store'

export function createApp({ Vue, I18n }) {
  const i18n = createI18n({ Vue, I18n })
  const app = new Vue({
    i18n,
    router,
    store,
    delimiters: ['[[', ']]'],
    data() {
      return {
        True: true,
        False: false,
        None: undefined,
      }
    },
  })
  Vue.prototype.$copyToClipboard = function copyToClipboard(value, messages) {
    const { success, error } = messages
    // HTTPS ок localhost
    navigator.clipboard.writeText(value)
      .then(() => {
        this.$notify(success || this._('Текст скопирован.'));
      })
      .catch(() => {
        this.$notify(error || this._('Something went wrong.'));
      });
  };

  return { router, store, app, i18n }
}
