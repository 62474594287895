import Vuex from 'vuex'
import { organization } from './modules/organization/organization'
import { selects } from './modules/selects/selects'
import { locations } from './modules/locations/locations'
import { account } from './modules/account/account'
import { aliases } from './modules/aliases'

export default function crateStore() {
  return new Vuex.Store({
    modules: {
      organization,
      selects,
      locations,
      account,
      aliases,
    },
  })
}
