import { prefixLanguage } from '@utils/urls'

const VERIFICATION_ENDPOINTS = {
  VERIFICATION: 'verification',
  VERIFICATION_PERSONAL_DATA: 'verification-personal-data',
  VERIFICATION_ADDRESS: 'verification-address',
  VERIFICATION_DOCUMENTS: 'verification-documents',
  VERIFICATION_SELFIE: 'verification-selfie',
}

export { VERIFICATION_ENDPOINTS }

const ROUTES_NAMES = {
  NO_ACCESS: 'no-access',
}

function checkPermission(to, from, next) {
  const { isAuthenticated } = window
  setTimeout(() => {
    if (isAuthenticated) {
      next()
    } else {
      next({ name: ROUTES_NAMES.NO_ACCESS })
    }
  }, 100)
}

const VERIFICATION_ROUTES = [
  {
    component: () => import('@app/auth/AccVerification'),
    path: prefixLanguage('/cabinet/verification'),
    name: VERIFICATION_ENDPOINTS.VERIFICATION,
    redirect: { name: VERIFICATION_ENDPOINTS.VERIFICATION_PERSONAL_DATA },
    beforeEnter: checkPermission,
    children: [
      {
        path: 'personal-data',
        name: VERIFICATION_ENDPOINTS.VERIFICATION_PERSONAL_DATA,
        component: () => import('@app/blocks/verification-steps/PersonalData'),
      },
      {
        path: 'address',
        name: VERIFICATION_ENDPOINTS.VERIFICATION_ADDRESS,
        component: () => import('@app/blocks/verification-steps/Address'),
      },
      {
        path: 'documents',
        name: VERIFICATION_ENDPOINTS.VERIFICATION_DOCUMENTS,
        component: () => import('@app/blocks/verification-steps/Documents'),
      },
      {
        path: 'photo',
        name: VERIFICATION_ENDPOINTS.VERIFICATION_SELFIE,
        component: () => import('@app/blocks/verification-steps/Photo'),
      },
    ],
  },
]

export default VERIFICATION_ROUTES
