<template lang="pug">
include ../Bemto
+b.confirm-modal.pa--round-xl
  +b.close-btn(
    @click.prevent='$emit("close")'
  )
    +e.cross &#10005;
  +b.P.text.--weight-bold.--size-lg.--size-2lg-sm.--align-center {{ confirmMessages.title }}
  +b.P.text.--weight-medium.--size-3xxs.--size-sm-md.--color-blue-grey.--align-center.--lh-4lg.ma--t-sm {{ confirmMessages.text }}
  row.ma--t-lg(
    :justify="['center']"
  )
    cell
      +b.BUTTON.el-btn.--default.pa--round-2xs(
        @click='confirmHandler'
      )
        +b.P.text.--weight-black.--size-sm {{ _('Подтвердить') }}
    cell
      +b.BUTTON.el-btn.--black.pa--round-2xs(
        @click="$emit('close')"
      )
        +b.P.text.--weight-black.--size-sm {{ _('Отмена') }}
</template>

<script>

export default {
  name: 'confirm-modal',
  props: {
    promise: {},
    confirmMessages: {},
  },
  methods: {
    confirmHandler() {
      this.promise(true)
      this.$emit('close')
    },
  },
}
</script>
