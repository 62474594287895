import { prefixAPI } from '../resource/api'

import {
  createResource,
  postResource,
  patchResource,
  deleteResource,
} from '../resource/resource'

const ACCOUNTS_MODEL = 'accounts/'

/* ACCOUNTS */
const PERSONAL_INFO = prefixAPI('verification/check-personal-info/', ACCOUNTS_MODEL)
const CHECK_ADDRESS = prefixAPI('verification/check-address/', ACCOUNTS_MODEL)
const DOCUMENTS = prefixAPI('verification/check-documents/', ACCOUNTS_MODEL)
const PHOTO = prefixAPI('verification/check-selfie/', ACCOUNTS_MODEL)
const ACC_CONFIGURATION = prefixAPI('configuration/', ACCOUNTS_MODEL)
const ACCOUNT = prefixAPI('current/', ACCOUNTS_MODEL)
const CONFIGURATION_IP = prefixAPI('configuration/ip/', ACCOUNTS_MODEL)
const REMOVE_IP = prefixAPI('configuration/ip/{id}/', ACCOUNTS_MODEL)
const CONFIGURATION_EMAIL = prefixAPI('configuration/change-email/', ACCOUNTS_MODEL)
const CONFIGURATION_EMAIL_VERIFY = prefixAPI('configuration/change-email/verify/', ACCOUNTS_MODEL)
const CONFIGURATION_PHONE = prefixAPI('configuration/change-phone/', ACCOUNTS_MODEL)
const CONFIGURATION_PHONE_VERIFY = prefixAPI('configuration/change-phone/verify/', ACCOUNTS_MODEL)

/* ACCOUNTS */

export const personalInfoAPI = createResource(
  PERSONAL_INFO,
  postResource
)
export const checkAddressAPI = createResource(
  CHECK_ADDRESS,
  postResource
)
export const documentsAPI = createResource(
  DOCUMENTS,
  postResource
)

export const photoAPI = createResource(
  PHOTO,
  patchResource
)

export const getAccountAPI = createResource(
  ACCOUNT
)

export const patchAccountAPI = createResource(
  ACCOUNT,
  patchResource
)

export const getAccountConfigurationAPI = createResource(
  ACC_CONFIGURATION
)

export const patchAccountConfigurationAPI = createResource(
  ACC_CONFIGURATION,
  patchResource
)

export const configurationIpAPI = createResource(
  CONFIGURATION_IP,
  postResource
)

export const removeIpAPI = createResource(
  REMOVE_IP,
  deleteResource
)

export const changeEmailAPI = createResource(
  CONFIGURATION_EMAIL,
  postResource
)

export const changeEmailVerifyAPI = createResource(
  CONFIGURATION_EMAIL_VERIFY,
  patchResource
)

export const changePhoneAPI = createResource(
  CONFIGURATION_PHONE,
  postResource
)

export const changePhoneVerifyAPI = createResource(
  CONFIGURATION_PHONE_VERIFY,
  patchResource
)
