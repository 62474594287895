import {
  countriesAPI,
} from '@requests/services/services'

import {
  citiesAPI,
  streetsAPI,
} from '@requests/services/catalog'

export const locations = {
  namespaced: true,
  state: {
    countries: null,
    cities: null,
    streets: null,
  },
  getters: {
    getCountries: state => state.countries,
    getCities: state => state.cities,
    getStreets: state => state.streets,
  },
  actions: {
    countriesRequest({ commit }) {
      return countriesAPI.execute().then(({ data: { item } }) => {
        commit('setCountries', item)
      })
    },
    citiesRequest({ commit }, payload) {
      return citiesAPI.execute(payload).then(({ data: { item } }) => {
        commit('setCities', item)
      })
    },
    streetsRequest({ commit }, payload) {
      return streetsAPI.execute(payload).then(({ data: { item } }) => {
        commit('setStreets', item)
      })
    },
  },
  mutations: {
    /* eslint-disable no-return-assign */
    setCountries: (state, response) => state.countries = response,
    setCities: (state, response) => state.cities = response,
    setStreets: (state, response) => state.streets = response,
  },
}
