import { selectsAPI } from '@requests/services/services'
import { merchantSelects } from '@requests/services/merchant.service'

export const selects = {
  namespaced: true,
  state: {
    selects: null,
    merchantSelects: null,
  },
  getters: {
    getSelects: state => state?.selects,
    getSingleSelect: state => (objectName, value) => {
      if (state?.selects && state?.selects[objectName]) {
        return state?.selects[objectName].find(list => list.value === value)
      }
      return {}
    },
    getMerchantSelects: state => state?.merchantSelects,
    getSingleMerchantSelect: state => (objectName, value) => {
      if (state?.merchantSelects && state?.merchantSelects[objectName]) {
        return state?.merchantSelects[objectName].find(list => list.value === value)
      }
      return {}
    },
  },
  actions: {
    selectsRequest({ commit }) {
      return selectsAPI.execute().then(({ data: { item } }) => {
        commit('setSelects', item)
      })
    },
    merchantSelectsRequest({ commit }) {
      merchantSelects.execute().then(({ data: { item } }) => {
        commit('setMerchantSelects', item)
      })
    },
  },
  mutations: {
    setSelects: (state, response) => { state.selects = response },
    setMerchantSelects: (state, response) => { state.merchantSelects = response },
  },
}
