import { curry } from 'ramda';
import makeTemplateGetter from '@aspectus/resource-template-url-getter';
import qs from 'qs'
import {
  simpleTransformer,
  jsonTransformer,
} from '../requests/resource/transformer';

const queryGetter = ({ limit, offset, filters }) => {
  const dynamicFilters = JSON.parse(JSON.stringify(filters || {}))
  /**
   * constance with filter names
   * values of this filters should be joined by comma
   */
  const globalFilteKeys = ['brand__in', 'category__in']

  /**
   * remove filters that should be joined by comma
   * from the list of filters that should be joined by repeating
   */
  if (dynamicFilters) {
    globalFilteKeys.forEach(k => {
      delete dynamicFilters[k]
    })
  }

  /**
   * create query string of filters that should be joined by repeating
   */
  const queryObj = { ...dynamicFilters, limit, offset }
  const dynamicQuery = qs.stringify(queryObj, { arrayFormat: 'repeat' })

  let globalQuery = ''

  if (filters) {
    /**
     * create query string of filters that should be joined by comma
     */
    globalFilteKeys.forEach(k => {
      if (filters[k]) {
        globalQuery += qs.stringify({ [k]: filters[k] }, { arrayFormat: 'comma' })
      }
    })
    /**
     * create global query string of filters
     */
    if (globalQuery) {
      globalQuery = decodeURIComponent(`${globalQuery}&`)
    }
  }

  return `${globalQuery}${dynamicQuery}`
}

const categoryGetter = makeTemplateGetter('{label}');

const orderMap = {
  label: categoryGetter,
  query: queryGetter,
}

const initOrder = {
  path: [
    'prefix',
    'query',
  ],
}

export const urlGetter = curry(([prefix, postfix], { path, query = [] }, p) => {
  const base = { prefix, postfix }
  const pathParts = path.reduce((acc, x, i) => {
    acc[i] = orderMap[x] ? orderMap[x](p) : base[x]
    return acc
  }, Array.from({ length: path.length }, () => null))
  const queryParams = query.reduce((acc, x) => {
    acc.push(orderMap[x](p))
    return acc
  }, [])
  const filteredPath = pathParts.filter(x => null !== x).join('/')
  const replaced = filteredPath.replace(/\/\//img, '/')
  return `${replaced}?${queryParams.join('')}`
});

export const friendlyUrlGenerator = (url, resource, order = initOrder) => resource
  .url(urlGetter(url, order))
  .transform(jsonTransformer)
  .transform(simpleTransformer)

// const filtersGetterBase = makeTemplateGetter('{;filters*}');
// const filtersGetter = parameters => filtersGetterBase(parameters).slice(1) || null

// const partGetter = (name, prefix) => {
//   const base = makeTemplateGetter(`${prefix}{/${name}}`);
//   return parameters => {
//     const result = base(parameters);
//     return result === prefix ? null : result;
//   };
// };

// const pageGetter = partGetter('page', 'page');
// const categoryGetter = makeTemplateGetter('{label}');
// const getParametersGetter = makeTemplateGetter('{?limit,offset}');

// const orderMap = {
//   label: categoryGetter,
//   filters: filtersGetter,
//   pagination_offset: getParametersGetter,
//   page: pageGetter,
// }

// const initOrder = {
//   path: [
//     'prefix',
//     'filters',
//   ],
// }

// export const urlGetter = curry(([prefix, postfix], { path, query = [] }, p) => {
//   const base = { prefix, postfix }
//   const pathParts = path.reduce((acc, x, i) => {
//     acc[i] = orderMap[x] ? orderMap[x](p) : base[x]
//     return acc
//   }, Array.from({ length: path.length }, () => null))
//   const queryParams = query.reduce((acc, x) => {
//     acc.push(orderMap[x](p))
//     return acc
//   }, [])
//   return (`${pathParts.filter(x => null !== x).join('/')}/`).replace(/\/\//img, '/') + queryParams.join('');
// });

// export const friendlyUrlGenerator = (url, resource, order = initOrder) => resource
//   .url(urlGetter(url, order))
//   .transform(jsonTransformer)
//   .transform(simpleTransformer)
