<template lang="pug">
include ../Bemto
+b.file-demonstration
  +b.P.text.--color-blue-grey.--weight-bold.icon-file.ma--r-sm
  +b.READ-MORE.text.--color-turquoise.--size-3xxs.--size-sm-sm(
    :text="file.file.name"
    :more-str="''"
    :max-chars="20"
  )
  +b.P.text.--color-blue-grey.--size-sm.ma--l-2xs.ma--r-2xs |
  +b.P.text.--color-blue-grey.--size-xxs.--size-sm-sm {{ (fileSize == 0 ? '< 1' : fileSize) + ' ' + 'Mb' }}
  +e.remove(
    @click="$emit('remove-file')"
  ) &#10005;
</template>

<script>
export default {
  name: 'file-demonstration',
  props: {
    file: {},
  },
  data() {
    return {
      fileSize: ((this.file.size / 1024) / 1024).toFixed(1),
    }
  },
}
</script>
