<template lang="pug">
include ../Bemto
+b.profile-modal
  +b.close-btn(
    @click.prevent='$emit("close")'
  )
    +e.cross &#10005;
  +b.P.text.--weight-bold.--size-2lg {{ _('Відновлення паролю') }}
  +b.VALIDATION-OBSERVER(
    ref='validator'
    v-slot="{ valid }"
    tag='form'
  )
    d-control-input.ma--t-lg(
      :input-label="_('Email/Номер телефону')"
      rules='required'
      v-model='form.login'
    )
    +b.P.control-descriptor__errors.ma--t-xs.text.--align-center(
      v-if='nonField'
    ) {{ nonField[0].message }}
    +b.BUTTON.profile-modal-btn.el-btn.--default.ma--t-sm(
      @click.prevent="prepareData(valid)"
    )
      +b.P.text.--weight-black.--size-2sm {{ _('Вiдновити') }}
</template>

<script>

import { resetPasswordCodeAPI } from '@requests/services/auth.service'
import RestoreSuccess from '@app/modals/RestoreSuccess'
import FormMixin, { defaultValidatorErrorsParser } from '@app/Forms/FormMixin'

export default {
  name: 'restore-password',
  mixins: [
    FormMixin,
  ],
  data() {
    return {
      nonField: null,
      form: {
        login: null,
      },
    }
  },
  methods: {
    updateValidator(errors) {
      this.nonField = errors.nonFieldErrors
      const parsed = defaultValidatorErrorsParser(errors)
      this.$refs.validator.setErrors(parsed)
    },
    async prepareData(valid) {
      this.$refs.validator.validate();
      if (!valid) return;
      this.submit(valid, this.form);
    },
    send() {
      return resetPasswordCodeAPI.execute({}, this.form).then(({ data: { item } }) => {
        sessionStorage.token = item.sessionToken
        sessionStorage.login = this.form.login
        this.$emit('close')
        this.$modal.show(RestoreSuccess, {
          info: this.$attrs,
        },
        {
          classes: ['defaultModal', 'vm--modal_sm'],
        })
      })
    },
  },
}
</script>
