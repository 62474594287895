import { prefixLanguage } from '@utils/urls'

const MERCHANT_ENDPOINTS = {
  LIST: 'companies',
  COMPANY_WALLETS: 'company-wallets',
  COMPANY_ORDERS: 'company-orders',
  COMPANY_TRANSACTIONS: 'company-transactions',
  COMPANY_SETTING: 'company-setting',
}

export { MERCHANT_ENDPOINTS }

const ROUTES_NAMES = {
  NO_ACCESS: 'no-access',
}

function checkPermission(to, from, next) {
  const { isAuthenticated } = window
  setTimeout(() => {
    if (isAuthenticated) {
      next()
    } else {
      next({ name: ROUTES_NAMES.NO_ACCESS })
    }
  }, 100)
}

const MERCHANT_ROUTES = [
  {
    component: () => import('@app/merchant/MerchantLayout'),
    path: prefixLanguage('/cabinet/companies/:id'),
    beforeEnter: checkPermission,
    children: [
      {
        path: '',
        name: MERCHANT_ENDPOINTS.SETTINGS,
        redirect: { name: MERCHANT_ENDPOINTS.LIST },
        component: () => import('@app/merchant/MerchantSettings'),
        children: [
          {
            path: 'wallets',
            name: MERCHANT_ENDPOINTS.COMPANY_WALLETS,
            component: () => import('@app/merchant/settings-tabs/Wallets'),
          },
          {
            path: 'orders',
            name: MERCHANT_ENDPOINTS.COMPANY_ORDERS,
            component: () => import('@app/merchant/settings-tabs/Orders'),
          },
          {
            path: 'transactions',
            name: MERCHANT_ENDPOINTS.COMPANY_TRANSACTIONS,
            component: () => import('@app/merchant/settings-tabs/Transactions'),
          },
          {
            path: 'setting',
            name: MERCHANT_ENDPOINTS.COMPANY_SETTING,
            component: () => import('@app/merchant/settings-tabs/Setting'),
          },
        ],
      },
      {
        path: prefixLanguage('/cabinet/companies'),
        name: MERCHANT_ENDPOINTS.LIST,
        component: () => import('@app/merchant/CompaniesList'),
      },
    ],
  },
]

export default MERCHANT_ROUTES
