import { getAliasesAPI } from '@requests/services/market.service'

export const aliases = {
  namespaced: true,
  state: {
    aliases: [],
  },
  getters: {
    getAliases: state => state?.aliases,
  },
  actions: {
    aliasesRequest({ commit }) {
      if (window.isAuthenticated) {
        return getAliasesAPI.execute().then(({ data: { item } }) => {
          commit('setAliases', item)
        })
      }
      return null
    },
  },
  mutations: {
    setAliases: (state, response) => { state.aliases = response },
  },
}
