import NeedAuth from './NeedAuth'
import TransferModal from './TransferModal'
import TopUpModal from './TopUpModal'
import ConfirmModal from './ConfirmModal'
import RestorePasswordModal from './RestorePasswordModal'
import RestoreSuccess from './RestoreSuccess'
import ChangePasswordSuccess from './ChangePasswordSuccess'
import ContinueReset from './ContinueReset'
import CodeModal from './CodeModal'
import DocRequirementsModal from './DocRequirementsModal'
import StateTransactionModal from './StateTransactionModal'
import VerificationSuccess from './VerificationSuccess'

export default function install(Vue) {
  Vue.component(NeedAuth.name, NeedAuth)
  Vue.component(TopUpModal.name, TopUpModal)
  Vue.component(ConfirmModal.name, ConfirmModal)
  Vue.component(TransferModal.name, TransferModal)
  Vue.component(RestorePasswordModal.name, RestorePasswordModal)
  Vue.component(ChangePasswordSuccess.name, ChangePasswordSuccess)
  Vue.component(RestoreSuccess.name, RestoreSuccess)
  Vue.component(ContinueReset.name, ContinueReset)
  Vue.component(CodeModal.name, CodeModal)
  Vue.component(DocRequirementsModal.name, DocRequirementsModal)
  Vue.component(StateTransactionModal.name, StateTransactionModal)
  Vue.component(VerificationSuccess.name, VerificationSuccess)
}
