<template lang="pug">
include ../Bemto
+b.el-footer(
  v-if="!isSiteMaintenance"
)
  +e.payment-wrapper.none--sm
    +b-context('el-footer')
      +e.payment(
        :style="{width: '56px', height: '38px'}"
      )
        +b.IMG(
          src="/static/img/mc.png"
          :style="{width: '26px', height: '17px'}"
        )
    +b-context('el-footer')
      +e.payment.ma--t-xs(
        :style="{width: '56px', height: '38px'}"
      )
        +b.IMG(
          :style="{width: '26px', height: '17px'}"
          src="/static/img/visa.png"
        )
  +b.container
    row(
      :justify="['between-xl']"
    )
      cell.ma--b-xl(
        :cols="['12', 'narrow-md']"
      )
        router-link(
          :to="{name: auth ? MARKET : LANDING}"
        )
          +b.IMG(
            v-if='organization'
            :src='organization.logo'
          )
        +b.none--xs.block--sm
          row.ma--t-xl(
            space="md"
          )
            cell
              +b-context('el-footer')
                +e.payment
                  +b.IMG(
                    src="/static/img/mc.png"
                  )
            cell
              +b-context('el-footer')
                +e.payment
                  +b.IMG(
                    src="/static/img/visa.png"
                  )
      cell.ma--b-xl(
        v-if='organization'
        :cols="['4-md', 'narrow-lg']"
      )
        +b.P.text.--color-white.--size-sm.--weight-medium {{ _('Контактні дані') }}
        +b.A.text.--color-blue-grey.--size-sm.--weight-medium.--pointer-turquoise.ma--t-lg.d--block(
          :href="`tel:${organization.phone}`"
        ) {{ organization.phone }}
        +b.A.text.--color-blue-grey.--size-sm.--weight-medium.--pointer-turquoise.ma--t-xs.d--block(
          :href="`mailto:${organization.email}`"
        ) {{ organization.email }}
        +b.P.text.--color-blue-grey.--size-sm.--weight-medium.ma--t-xs(
        ) {{ organization.address }}
      cell.ma--b-xl(
        :cols="['4-md', 'narrow-lg']"
      )
        +b.P.text.--color-white.--size-sm.--weight-medium {{ _('Додатково') }}
        +b.A.footer-links.text.--color-blue-grey.--size-sm.--weight-medium.--pointer-turquoise(
          v-for='item, index in additionalLinks'
          :class="[index === 0 ? 'ma--t-lg' : 'ma--t-xs']"
          :href='item.url'
          :target='item.target'
        ) {{ item.title }}
      cell.ma--b-xl(
        :cols="[]"
      )
        +b.P.text.--color-white.--size-sm.--weight-medium {{ _('Ми у соціальних мережах') }}
        row.ma--t-lg(
          v-if='organization'
          space='none'
        )
          cell(
            v-for='item, index in organization.socialNetworks'
            :key='index'
          )
            +b-context('el-footer')
              +e.A.social(
                :href='item.link'
                target="_blank"
                :class="[index+1 < organization.socialNetworks.length ? 'ma--r-xs' : '']"
              )
                +b.IMG(
                  :src='item.icon'
                )
        +b.P.text.--color-white.--size-sm.--weight-medium
            | {{ _('This site is protected by reCAPTCHA and the Google') + ' ' }}
            br.none--xs.block--md
            +b.A.text.--color-blue-grey.--size-sm.--weight-medium.--pointer-turquoise(
              href='https://policies.google.com/privacy',
              target='_blank',
              rel='noopener nofollow noindex noreferrer'
            ) {{ _('Privacy Policy') + ' ' }}
            | {{ _('and') + ' ' }}
            +b.A.text.--color-blue-grey.--size-sm.--weight-medium.--pointer-turquoise(
              href='https://policies.google.com/terms',
              target='_blank',
              rel='noopener nofollow noindex noreferrer'
            ) {{ _('Terms of Service') + ' ' }}
            | {{ _('apply.') }}

</template>

<script>

import { mapGetters, mapActions } from 'vuex'
import { prefixLanguage } from '@utils/urls'
import { COMMON_ENDPOINTS } from '@routes/commons/commons'
import {
  additionalLinksAPI,
} from '@requests/services/services'

export default {
  name: 'footer-vue',
  data() {
    return {
      isSiteMaintenance: prefixLanguage('/maintenance/') === window.location.pathname,
      additionalLinks: [],
      auth: window.isAuthenticated,
      LANDING: COMMON_ENDPOINTS.LANDING,
      MARKET: COMMON_ENDPOINTS.MARKET,
    }
  },
  computed: {
    ...mapGetters('organization', {
      organization: 'getOrganization',
    }),
  },
  mounted() {
    this.getLinks()
    this.organizationRequest()
  },
  methods: {
    ...mapActions('organization', [
      'organizationRequest',
    ]),
    getLinks() {
      additionalLinksAPI.execute().then(({ data: { items } }) => {
        this.additionalLinks = items
      })
    },
  },
}
</script>
