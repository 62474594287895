function scrollFunction() {
  const header = document.querySelector('.js-header')
  if (header) {
    const offset = 10
    if (window.pageYOffset > offset) {
      header.classList.add('is-active')
    } else {
      header.classList.remove('is-active')
    }
  }
}

export const scrolly = () => {
  scrollFunction()
  window.addEventListener('scroll', scrollFunction, { passive: true })
}
