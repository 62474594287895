<template lang="pug">
include ../Bemto
+b.application-main
  //- header-vue
  router-view
  //- footer-vue
  notifications(
    position="bottom right"
  )
</template>

<script>
import { mapActions } from 'vuex'

export default {
  name: 'main-vue',
  async created() {
    await this.selectsRequest()
    await this.organizationRequest()
    await this.countriesRequest()
    await this.accountRequest()
    this.aliasesRequest()
  },
  methods: {
    ...mapActions('organization', [
      'organizationRequest',
    ]),
    ...mapActions('selects', [
      'selectsRequest',
    ]),
    ...mapActions('locations', [
      'countriesRequest',
    ]),
    ...mapActions('account', [
      'accountRequest',
    ]),
    ...mapActions('aliases', [
      'aliasesRequest',
    ]),
  },
}
</script>
