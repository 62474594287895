import { prefixLanguage } from '@utils/urls'

const AUTH_ENDPOINTS = {
  AUTH: 'auth',
  LOGIN: 'login',
  REGISTRATION: 'registration',
}

export { AUTH_ENDPOINTS }

const AUTH_ROUTES = [
  {
    component: () => import('@app/auth/AuthLayout'),
    path: prefixLanguage('/auth'),
    name: AUTH_ENDPOINTS.AUTH,
    redirect: { name: AUTH_ENDPOINTS.LOGIN },
    children: [
      {
        component: () => import('@app/auth/Authorization'),
        path: 'login',
        name: AUTH_ENDPOINTS.LOGIN,
      },
      {
        component: () => import('@app/auth/Registration'),
        path: 'registration',
        name: AUTH_ENDPOINTS.REGISTRATION,
      },
    ],
  },
]

export default AUTH_ROUTES
