/* eslint-disable import/named */
import {
  createResource,
} from '../resource/resource'

import { ENDPOINTS } from '../ENDPOINTS'

export const textPageApi = createResource(
  ENDPOINTS.TEXT_PAGE
)
export const selectsAPI = createResource(
  ENDPOINTS.SELECTS
)
export const countriesAPI = createResource(
  ENDPOINTS.COUNTRIES
)

// export const citiesAPI = createResource(
//   ENDPOINTS.CITIES
// )

// export const streetsAPI = createResource(
//   ENDPOINTS.STREETS
// )

/* PAGES */

export const landingData = createResource(
  ENDPOINTS.LANDING
)
export const organizationAPI = createResource(
  ENDPOINTS.ORGANIZATION
)

export const additionalLinksAPI = createResource(
  ENDPOINTS.ADDITIONAL_LINKS
)
