import { prefixLanguage } from '@utils/urls'

export function defaultDataTransformer(response) {
  return response.json().then(result => result)
}

export function simpleTransformer(response) {
  return response
}

export function fetcher(resource, init = {}) {
  return fetch(resource, init)
}

export function transformDataFetcher(f, transformer) {
  return function fn() {
    // eslint-disable-next-line prefer-rest-params
    return f.apply(this, arguments).then(transformer)
  }
}

export function jsonTransformer(response) {
  return response.json()
}

export function raiseHttpErrorTransformer(response) {
  if (!response.ok) {
    throw response
  }
  return response
}

export function maintenanceRedirect(response) {
  if (423 === response.status && prefixLanguage('/maintenance/') !== window.location.pathname) {
    window.location.href = prefixLanguage('/maintenance/')
  }

  return response
}
