import { prefixLanguage } from '@utils/urls'
import Landing from '@app/pages/Landing'

const COMMON_ENDPOINTS = {
  LANDING: 'landing',
  CONFIRMATION: 'confirmation',
  RESET_PASSWORD: 'reset-password',
  MARKET: 'market',
  EXCHANGE: 'exchange',
  CASH: 'cash',
  SALE: 'sale',
  TEXT_PAGE: 'text-page',
  PAYMENT: 'payment',
  NOT_FOUND: 'not-found',
  NO_ACCESS: 'no-access',
  SITE_MAINTENANCE: 'site-maintenance',
}

export { COMMON_ENDPOINTS }

function checkPermission(to, from, next) {
  const { isAuthenticated } = window
  setTimeout(() => {
    if (isAuthenticated) {
      next()
    } else {
      next({ name: COMMON_ENDPOINTS.NO_ACCESS })
    }
  }, 100)
}

const COMMON_ROUTES = [
  {
    component: Landing,
    path: prefixLanguage('/'),
    name: COMMON_ENDPOINTS.LANDING,
  },
  {
    component: () => import('@app/auth/Confirmation'),
    path: prefixLanguage('/confirm'),
    name: COMMON_ENDPOINTS.CONFIRMATION,
  },
  {
    component: () => import('@app/auth/ResetPassword'),
    path: prefixLanguage('/reset-password'),
    name: COMMON_ENDPOINTS.RESET_PASSWORD,
  },
  {
    component: () => import('@app/market/Market'),
    path: prefixLanguage('/cabinet/market'),
    name: COMMON_ENDPOINTS.MARKET,
    beforeEnter: checkPermission,
  },
  {
    component: () => import('@app/exchange/Exchange'),
    path: prefixLanguage('/cabinet/exchange'),
    name: COMMON_ENDPOINTS.EXCHANGE,
    beforeEnter: checkPermission,
  },
  {
    component: () => import('@app/cash/Cash'),
    path: prefixLanguage('/cash'),
    name: COMMON_ENDPOINTS.CASH,
  },
  {
    component: () => import('@app/payment/PaymentLayout'),
    path: prefixLanguage('/cabinet/payment'),
    name: COMMON_ENDPOINTS.PAYMENT,
    beforeEnter: checkPermission,
  },
  {
    component: () => import('@app/pages/TextPage'),
    path: prefixLanguage('/page/:slug'),
    name: COMMON_ENDPOINTS.TEXT_PAGE,
  },
  {
    component: () => import('@app/errorsPages/NoAccess'),
    path: prefixLanguage('/403'),
    name: COMMON_ENDPOINTS.NO_ACCESS,
  },
  {
    component: () => import('@app/errorsPages/NotFound'),
    path: prefixLanguage('/404'),
    alias: ['*'],
    name: COMMON_ENDPOINTS.NOT_FOUND,
  },
  {
    component: () => import('@app/errorsPages/SiteMaintenance'),
    path: prefixLanguage('/maintenance/'),
    alias: ['*'],
    name: COMMON_ENDPOINTS.SITE_MAINTENANCE,
  },
]

export default COMMON_ROUTES
