import { prefixAPI } from '../resource/api'
import {
  createResource,
  postResource,
} from '../resource/resource'

const AUTH_MODEL = 'auth/'

const REGISTRATION = prefixAPI('registration/', AUTH_MODEL)
const REGISTRATION_VERIFY = prefixAPI('registration/verify/', AUTH_MODEL)
const CHANGE_PASSWORD = prefixAPI('change-password/', AUTH_MODEL)
const RESET_PASSWORD = prefixAPI('reset-password/', AUTH_MODEL)
const RESET_PASSWORD_CODE = prefixAPI('reset-password/send-code/', AUTH_MODEL)
const RESET_PASSWORD_VERIFY = prefixAPI('reset-password/verify/', AUTH_MODEL)
const LOGIN = prefixAPI('login/', AUTH_MODEL)
const LOGOUT = prefixAPI('logout/', AUTH_MODEL)
const TOTP = prefixAPI('totp/', AUTH_MODEL)
const TOTP_VERIFY = prefixAPI('totp/verify/', AUTH_MODEL)

/* AUTH */

export const registrationAPI = createResource(
  REGISTRATION,
  postResource
)
export const registrationVerifyAPI = createResource(
  REGISTRATION_VERIFY,
  postResource
)
export const loginAPI = createResource(
  LOGIN,
  postResource
)
export const logoutAPI = createResource(
  LOGOUT,
  postResource
)

export const changePasswordAPI = createResource(
  CHANGE_PASSWORD,
  postResource
)

export const resetPasswordAPI = createResource(
  RESET_PASSWORD,
  postResource
)

export const resetPasswordCodeAPI = createResource(
  RESET_PASSWORD_CODE,
  postResource
)

export const resetPasswordVerifyAPI = createResource(
  RESET_PASSWORD_VERIFY,
  postResource
)

export const authTotpAPI = createResource(
  TOTP,
  postResource
)

export const authTotpVerifyAPI = createResource(
  TOTP_VERIFY,
  postResource
)
