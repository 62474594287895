import { prefixAPI } from './resource/api'

const ENDPOINTS = {
  ORGANIZATION: prefixAPI('organization/'),
  SELECTS: prefixAPI('selects/'),
  COUNTRIES: prefixAPI('countries/'),
  /* PAGES */
  LANDING: prefixAPI('pages/landing/'),
  TEXT_PAGE: prefixAPI('pages/{slug}/'),
  ADDITIONAL_LINKS: prefixAPI('pages/additional-links/'),

}

export { ENDPOINTS }
