<template lang="pug">
  include ../Bemto
  div(
    ref="descriptor"
    v-bem=''
    :class="[inputClass, {'d-control-input--icon_prepend': prependIcon, 'd-control-input--icon_append': appendIcon}]"
  )
    //- div(v-bem.element-wrap='')
    // FIXME: return v-bem.element=''
    slot(
      v-if="$scopedSlots.prependIcon || prependIcon"
      name="prependIcon"
    )
      component(
        v-if="prependIcon"
        :is="prependComponent"
        :prepend-icon="prependIcon"
        :prepend-icon-func="prependIconFunc"
      )
    component.d-control-input__element(
      :class="{'d-control-input__element--icon_prepend': prependIcon, 'd-control-input__element--icon_append': appendIcon}"
      :is="element",
      :value.prop='value',
      ref='input'
      v-on="listeners"
      :id='id',
      v-bind='$attrs'
      :type='type'
      v-model='model',
    )
      template(
        v-slot:[name]="binded"
        v-for="(name, key) in eSlots"
      )
        slot(
          v-bind="binded"
          :name="key"
        )
    slot(name="label-slot")
      component(
        :is='labelComponent',
        :id='id',
        :value='value',
        :labelText='labelText',
        :input-label='inputLabel',
        :is-required='required'
        :hint="hint"
        :target="target"
        v-if="inputLabel"
      )
    slot(
      v-if="$scopedSlots.appendIcon || appendIcon"
      name="appendIcon"
    )
      component(
        v-if="appendIcon"
        :is="appendComponent"
        :append-icon="appendIcon"
        :append-icon-func="appendIconFunc"
      )
    +b.d-control-input__icon-password.--append.--color_text-card.--pointer(
      v-if='type == "password"'
      @click.prevent='changeType()',
      :class='!show ? "icon-eyes1" : "icon-eyes2"'
    )
</template>

<script>

const eSlotsResolver = (acc, key) => {
  acc[key] = key
  return acc
}

const getESlots = keys => keys
  .reduce(eSlotsResolver, {})

export default {
  name: 'element-input',
  props: [
    'prependComponent',
    'prependIcon',
    'prependIconFunc',
    'appendComponent',
    'appendIcon',
    'appendIconFunc',
    'inputClass',
    'labelComponent',
    'id',
    'value',
    'labelText',
    'inputLabel',
    'required',
    'providerContext',
    'type',
    'element',
    'mask',
    'clearMaskOnLostFocus',
    'hint',
    'target',
    'triggerOpenMultiselect',
    'triggerCloseMultiselect',
  ],
  block: 'd-control-input',
  data() {
    return {
      input: '',
      show: false,
    }
  },
  watch: {
    value: {
      handler() {
        this.input = this.value
      },
      deep: true,
      immediate: true,
    },
    triggerOpenMultiselect(newVal) {
      if (newVal) {
        console.log('newVal', newVal);
        this.$refs.input.$parent.$refs.multiselect.activate()
      }
    },
    triggerCloseMultiselect(newVal) {
      if (newVal) {
        this.$refs.input.$parent.$refs.multiselect.deactivate()
      }
    },
  },
  computed: {
    eSlots() {
      return getESlots(Object.keys(this.$scopedSlots))
    },
    listeners() {
      return {
        ...this.$listeners,
        input: $event => this.$emit('input', this.getEvent($event)),
      }
    },
    model: {
      get() {
        return this.input
      },
      set(e) {
        this.input = e
      },
    },
  },
  mounted() {
    console.log(this.$refs.input);
  },
  methods: {
    getEvent($event) {
      if ($event && $event.timeStamp) {
        return $event.target[this.getKey()]
      }
      return $event
    },
    getKey() {
      return 'checkbox' === this.type ? 'checked' : 'value'
    },
    changeType() {
      this.show = !this.show
      if (this.show) {
        this.$refs.input.type = 'text'
      } else {
        this.$refs.input.type = this.type
      }
    },
  },
}
</script>
