import { prefixLanguage } from '@utils/urls'

const PROFILE_ENDPOINTS = {
  PROFILE: 'profile',
  SETTINGS: 'profile-settings',
  PROFILE_SETTINGS_INFORMATION: 'profile-settings-information',
  PROFILE_SETTINGS_SECURITY: 'profile-settings-security',
  PROFILE_SETTINGS_WHITELIST: 'profile-settings-whitelist',
  PROFILE_SETTINGS_ALERTS: 'profile-settings-alerts',
  ACCOUNTS: 'cabinet:accounts',
  REFERRALS: 'cabinet:referrals',
  CASHBACK: 'cabinet:cashback',
  CASH: 'cabinet:cash',
}

export { PROFILE_ENDPOINTS }

const ROUTES_NAMES = {
  NO_ACCESS: 'no-access',
}

function checkPermission(to, from, next) {
  const { isAuthenticated } = window
  // setTimeout(() => {
  if (isAuthenticated) {
    next()
  } else {
    next({ name: ROUTES_NAMES.NO_ACCESS })
  }
  // }, 100)
}

const PROFILE_ROUTES = [
  {
    component: () => import('@app/profile/ProfileLayout'),
    path: prefixLanguage('/cabinet/profile'),
    beforeEnter: checkPermission,
    children: [
      {
        path: '',
        name: PROFILE_ENDPOINTS.SETTINGS,
        redirect: { name: PROFILE_ENDPOINTS.PROFILE_SETTINGS_INFORMATION },
        component: () => import('@app/profile/ProfileSettings'),
        children: [
          {
            path: 'information',
            name: PROFILE_ENDPOINTS.PROFILE_SETTINGS_INFORMATION,
            component: () => import('@app/profile/settings-tabs/Information'),
          },
          {
            path: 'security',
            name: PROFILE_ENDPOINTS.PROFILE_SETTINGS_SECURITY,
            component: () => import('@app/profile/settings-tabs/Security'),
          },
          {
            path: 'whitelist',
            name: PROFILE_ENDPOINTS.PROFILE_SETTINGS_WHITELIST,
            component: () => import('@app/profile/settings-tabs/WhiteList'),
          },
          {
            path: 'alerts',
            name: PROFILE_ENDPOINTS.PROFILE_SETTINGS_ALERTS,
            component: () => import('@app/profile/settings-tabs/Alerts'),
          },
        ],
      },
      {
        path: 'accounts',
        name: PROFILE_ENDPOINTS.ACCOUNTS,
        component: () => import('@app/profile/Accounts'),
      },
      {
        path: 'referral',
        name: PROFILE_ENDPOINTS.REFERRALS,
        component: () => import('@app/profile/Referral'),
      },
      {
        path: 'cashback',
        name: PROFILE_ENDPOINTS.CASHBACK,
        component: () => import('@app/profile/Cashback'),
      },
      {
        path: 'cash',
        name: PROFILE_ENDPOINTS.CASH,
        component: () => import('@app/profile/Cash'),
      },
    ],
  },
]

export default PROFILE_ROUTES
