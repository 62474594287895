<template lang="pug">
include ../Bemto
+b.file-uploader
  +e.INPUT.hidden-input(
    :ref="'fileUploader'"
    type='file'
    accept='.pdf, .png, .jpg'
    :name='name'
    :id='name'
    @change='uploadSource'
  )
  +e.LABEL.fake-input(
    :for='name'
  )
    slot
</template>

<script>
export default {
  name: 'file-uploader',
  props: {
    name: {},
  },
  data() {
    return {
      file: null,
    }
  },
  methods: {
    uploadSource(event) {
      const { ...files } = event.target.files
      Object.entries(files).forEach(file => {
        const reader = new FileReader()
        reader.onload = () => {
          this.prefillSrc(file[1].name, reader.result, file[1].size)
        }
        reader.readAsDataURL(file[1])
        this.$nextTick(() => {
          this.$refs.fileUploader.value = null
        })
      })
    },
    prefillSrc(name, data, size) {
      // this.file = {
      //   file: data,
      //   name,
      // }
      const fileData = {
        size,
        file: {
          file: data,
          name,
        },
      }
      this.$emit('file-data', fileData || null)
    },
  },
}
</script>
