<template lang="pug">
include ../Bemto
+b.doc-requirements-modal.pa--round-xl
  +b.close-btn(
    @click.prevent='$emit("close")'
  )
    +e.cross &#10005;
  +b.P.text.--weight-bold.--size-lg.--size-2lg-sm.ma--b-2xs {{ _('Вимоги до документів') }}
  +b.P {{ _('Вимоги до документів text') }}
</template>

<script>

export default {
  name: 'doc-requirements',
}
</script>
