import { prefixLanguage } from '@utils/urls'
// import smoothscroll from 'smoothscroll-polyfill'

export function scrollTo(gotoBlock) {
  const gotoBlockValue = gotoBlock.getBoundingClientRect().top - 100
  const scrollTop = window.pageYOffset
  window.scrollTo({
    top: gotoBlockValue + scrollTop - 120,
    behavior: 'smooth',
  })
}
export function onMenuLink(e) {
  // smoothscroll.polyfill()
  const menuLink = e.target
  const gotoBlock = document.getElementById(menuLink.dataset.goto)
  if (gotoBlock) {
    scrollTo(gotoBlock)
    e.preventDefault()
  } else {
    window.location.assign(
      prefixLanguage(`${window.location.origin}/#${menuLink.dataset.goto.split('-')[0]}`, { language: window.language })
    )
  }
}

export const scrollArchon = () => {
  const archons = [...document.getElementsByClassName('js-header-link')]
  archons.forEach(item => {
    item.addEventListener('click', onMenuLink)
  })
  if (window.location.hash) {
    setTimeout(() => {
      const gotoBlock = document.getElementById(window.location.hash.split('#').join(''))
      if (gotoBlock) {
        scrollTo(gotoBlock)
      }
    }, 600)
  }
}
