import Controls from './Controls'
import Pagination from './Pagination'
import bar from './bar'
import commons from './commons'
import inputs from './inputs'
import triggers from './triggers'
import modals from './modals'
import main from './main'
import payment from './payment'
import blocks from './blocks'
import cards from './cards'
import ui from './ui'

const CheckOut = () => import('./pages/CheckOut.vue')
const CheckoutSuccess = () => import('./pages/CheckoutSuccess.vue')

export default function iComponents(Vue) {
  Vue.component('check-out', CheckOut)
  Vue.component('checkout-success', CheckoutSuccess)
  Vue.use(Pagination)
  Vue.use(Controls)
  Vue.use(commons)
  Vue.use(main)
  Vue.use(cards)
  Vue.use(blocks)
  Vue.use(triggers)
  Vue.use(bar)
  Vue.use(inputs)
  Vue.use(payment)
  Vue.use(modals)
  Vue.use(ui)
}
