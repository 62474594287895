import { organizationAPI } from '@requests/services/services'

export const organization = {
  namespaced: true,
  state: {
    organization: {},
  },
  getters: {
    getOrganization: state => state.organization,
  },
  actions: {
    organizationRequest({ commit }) {
      return organizationAPI.execute().then(({ data: { item } }) => {
        commit('setOrganization', item)
      })
    },
  },
  mutations: {
    // eslint-disable-next-line
    setOrganization: (state, response) => state.organization = response,
  },
}
