<template lang="pug">
include ../Bemto
+b.VALIDATION-OBSERVER.profile-modal(
  ref="validator"
  tag="form"
)
  +b.close-btn(
    @click.prevent='$emit("close")'
  )
    +e.cross &#10005;
  +b.P.text.--weight-bold.--size-2lg {{ _('Відновлення паролю') }}
  d-control-input.ma--t-lg(
    :input-label="_('Email/Номер телефону')"
    v-model='form.login'
    disabled
  )
  +b.reset-warning.ma--t-sm
    +b.P.text.--size-3xxs.--color-blue-grey.--align-center {{ _('Вам було надіслано повідомлення з кодом') }}
  code-confirm.ma--t-sm(
    :loading="isLoad"
    @confirm='prepareData'
    @repeat='repeatCode'
  )
  +b.P.control-descriptor__errors.ma--t-xs.text.--align-center(
    v-if='nonField'
  ) {{ nonField[0].message }}
</template>

<script>

import FormMixin, { defaultValidatorErrorsParser } from '@app/Forms/FormMixin'
import {
  resetPasswordVerifyAPI,
  resetPasswordCodeAPI,
} from '@requests/services/auth.service'
import { COMMON_ENDPOINTS } from '@routes/commons/commons'

export default {
  name: 'continue-reset',
  mixins: [
    FormMixin,
  ],
  data() {
    return {
      isLoad: false,
      nonField: null,
      form: {
        login: sessionStorage.login,
        sessionToken: sessionStorage.token,
        securityCode: null,
      },
    }
  },
  methods: {
    repeatCode() {
      const data = {
        login: this.form.login,
      }
      resetPasswordCodeAPI.execute({}, data).then(({ data: { item } }) => {
        this.form.sessionToken = item.sessionToken
      })
    },
    async prepareData(code) {
      this.isLoad = true
      this.form.securityCode = code
      this.$refs.validator.validate().then(valid => {
        if (!valid) return;

        this.submit(valid, this.form);
      })
    },
    updateValidator(errors) {
      this.nonField = errors.nonFieldErrors
      const parsed = defaultValidatorErrorsParser(errors)
      this.$refs.validator.setErrors(parsed)
    },
    send(data) {
      return resetPasswordVerifyAPI.execute({}, data).then(() => {
        this.$emit('close')
        this.$router.push({ name: COMMON_ENDPOINTS.RESET_PASSWORD })
      })
        .finally(() => {
          this.isLoad = false
        })
    },
  },
}
</script>
