<template lang="pug">
include ../Bemto
+b.state-transaction
  +b.close-btn(
    @click.prevent='closeHandler'
  )
    +e.cross &#10005;
  +b.transaction-content
    +e.IMG.bg(
      src='/static/img/transaction.png'
    )
    +e.circle
      +b.IMG(
        :src='info.icon'
      )
  +b.P.text.--align-center.--weight-bold.--size-lg {{ info.text }}
  +e.BUTTON.transaction-confirm.el-btn.--default.ma--t-sm(
    v-if="info.type === 'success' || info.type === 'fail'"
    @click="closeHandler"
  )
    +b.P.text.--weight-black.--size-2sm {{ _('OK') }}
</template>

<script>

export default {
  name: 'state-transaction',
  props: {
    info: {},
  },
  mounted() {
    if ('success' === this.info.type || 'fail' === this.info.type) {
      // this.closeTimer()
    }
  },
  methods: {
    // closeTimer() {
    //   setTimeout(this.closeHandler, 5000)
    // },
    closeHandler() {
      this.$emit('close')
      if ('success' === this.info.type || 'fail' === this.info.type) {
        window.location.reload()
      }
    },
  },
}
</script>
