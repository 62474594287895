<template lang="pug">
include ../Bemto
+b.el-checkbox
  +e.INPUT.hidden-input(
    type='checkbox'
    :name='name'
    :id='name'
    v-model='value'
    @change="$emit('checkbox-data', value)"
  )
  +e.wrapper
    +e.LABEL.fake-checkbox(
      :for='name'
    )
  +e.LABEL.label(
    :for='name'
  )
    slot
</template>

<script>
export default {
  name: 'el-checkbox',
  props: {
    name: {},
  },
  data() {
    return {
      value: null,
    }
  },
}
</script>
