<template lang="pug">
include ../Bemto
+b.reset-modal.profile-modal
  +b.close-btn(
    @click.prevent='$emit("close")'
  )
    +e.cross &#10005;
  +e.P.title.text.--weight-bold.--size-lg.--size-2lg-sm.--align-center {{ info.title }}
  +e.BUTTON.continue-btn.el-btn.--default.ma--t-sm(
    @click="$emit('close')"
  )
    +b.P.text.--weight-black.--size-2sm {{ _('OK') }}
</template>

<script>

export default {
  name: 'change-success',
  props: {
    info: {},
  },
}
</script>
