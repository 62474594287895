<template lang="pug">
include ../Bemto
+b.HEADER.el-header.js-header(
  v-if="!isSiteMaintenance"
  ref='header'
  :style="{background: $route.name != 'landing' ? '#02131C' : ''}"
)
  +b.desktop-auth(
    v-if='isAuth'
  )
    row(
      :align="'center'"
      :justify="['between-xl']"
    )
      cell
        router-link.block(
          style="width: '153px'"
          :to="{name: LANDING}"
        )
          +b.IMG.block(
            v-if='organization'
            :src='organization.logo'
            width="153"
            height="16"
          )
      cell.ma--l-xl
        row()
          cell(
            :cols="[]"
            v-for='item, index in authLinks'
            :key='index'
          )
            router-link(
              :to="item.to"
            )
              +b.P.header-link.text.--size-xs.--weight-regular(
                :class="[index+1 < headerLinks.length ? 'ma--r-xs' : '']"
              ) {{ item.name }}
      cell.header-accounts-list(
        v-if='wallets'
      )
        accounts-bar(
          :wallets='wallets'
        )
      cell.header-accounts(
        cols="auto"
      )
        row(
          v-if='wallets'
          justify="end"
        )
          cell
            row(
              :align="'center'"
            )
              cell
                router-link(:to="{name: walletsLinks[0]}")
                  +b.invoice
                    +e.image.IMG(
                      src='/static/img/purse.png'
                    )
              cell
                +b.P.text.--color-blue-grey.--size-2xxs.--weight-bold {{ _('Основний рахунок') }}
                +b.P.text.--color-white.--size-2sm.--weight-bold {{ mainAcc.displayedValue }}
              cell
                modal-trigger(
                  name='topUpModal'
                  :url="account.verificationRequiredForReplenishment && account.verified !== 1 ? 'modals/NeedVerification' : 'modals/TopUpModal'"
                  :classes="account.verificationRequiredForReplenishment && account.verified !== 1 ? ['vm--modal_2sm'] : ['vm--modal_warning']"
                  :wallet='mainAcc.wallet'
                )
                  template(#default='{ open }')
                    +b.header-add(
                      @click.prevent='open'
                    )
                      +b.P.text.--size-lg +
          cell.referral-cell
            row(
              :align="'center'"
            )
              cell
                router-link(:to="{name: walletsLinks[1]}")
                  +b.invoice
                    +e.image.IMG(
                      src='/static/img/user+.png'
                    )
              cell
                +b.P.text.--color-blue-grey.--size-2xxs.--weight-bold {{ _('Реферальний рахунок') }}
                +b.P.text.--color-white.--size-2sm.--weight-bold {{ referralAcc.displayedValue }}
              cell
                +b.BUTTON.header-add.ds-link(
                  :disabled="!(Number(referralAcc.wallet && referralAcc.wallet.amount || 0))"
                  @click='confirmTransaction(referralAcc.wallet)'
                )
                  +b.P.icon-arrow.text.--size-2xxs
          cell
            row(
              :align="'center'"
            )
              cell
                router-link(:to="{name: walletsLinks[2]}")
                  +b.invoice
                    +e.image.IMG(
                      src='/static/img/percent.png'
                    )
              cell
                +b.P.text.--color-blue-grey.--size-2xxs.--weight-bold {{ _('Кешбек') }}
                +b.P.text.--color-white.--size-2sm.--weight-bold {{ cashbackAcc.displayedValue }}
              cell
                +b.BUTTON.header-add.ds-link(
                  :disabled="!(Number(cashbackAcc.wallet && cashbackAcc.wallet.amount || 0))"
                  @click='confirmTransaction(cashbackAcc.wallet)'
                )
                  +b.P.icon-arrow.text.--size-2xxs
      cell(
        v-if='selects'
      )
        d-control-multiselect.language-multiselect(
          :options="selects.languages"
          :allow-empty="false"
          :searchable="false"
          label='value'
          v-model='currentLanguage'
          @input='setLang($event.value)'
        )
      cell
        el-notifications(
          :did='account.did'
        )
      cell
        userbar(
          :avatar="account.selfie"
          :account="account"
          :wid='account.wid'
        )
  +b.container
    +b.desktop-none-auth(
      v-show='!isAuth'
    )
      row(
        :justify="'between'"
        :align="'center'"
      )
        cell
          row(align="center")
            cell
              router-link.block(
                :to="{name: LANDING}"
              )
                +b.IMG.block(
                  v-if='organization'
                  :src='organization.logo'
                )
            cell.ma--l-sm.ma--l-3xxl-mac
              row(space="lg")
                cell(
                  v-for='item, index in headerLinks'
                  :key='index'
                )
                  +b.A.header-link.text.--size-xs.--weight-regular(
                    v-if="item.anchor"
                    @click='e => { onMenuLink(e); }'
                    :data-goto='item.anchor'
                    :class="[index+1 < headerLinks.length ? 'ma--r-xs' : '']"
                  ) {{ item.name }}
                  +b.ROUTER-LINK.header-link.text.--size-xs.--weight-regular(
                    v-else
                    :to="{ path: item.to}"
                    :class="[index+1 < headerLinks.length ? 'ma--r-xs' : '']"
                  ) {{ item.name }}
        cell
          row(
            :align="'center'"
            space="lg"
          )
            cell.ma--r-sm(
              :cols="[]"
              v-if='selects'
            )
              d-control-multiselect.language-multiselect(
                :options="selects.languages"
                :allow-empty="false"
                :searchable="false"
                label='value'
                v-model='currentLanguage'
                @input='setLang($event.value)'
              )
            template(v-if="auth")

              cell(
                :cols="[]"
              )
                el-notifications(
                  :did='account.did'
                )
              cell(
                :cols="[]"
              )
                userbar(
                  :avatar="account.selfie"
                  :account="account"
                  :wid='account.wid'
                )
            template(v-else)
              cell(
                :cols="[]"
              )
                router-link(
                  :to="{name: LOGIN}"
                )
                  +b.BUTTON.login-btn
                    +b.P.text.--size-2sm.--weight-medium {{ _('Увійти') }}
              cell(
                :cols="[]"
              )
                router-link(
                  :to="{name: REGISTRATION}"
                )
                  +b.BUTTON.register-btn
                    +b.P.text.--size-2sm.--weight-medium {{ _('Реєстрація') }}
    +b.mobile-none-auth
      row(
        :justify="['between']"
        :align="'center'"
        space='lg'
      )
        cell(
          :cols="['narrow', '3-sm', '4-md', '3-lg']"
        )
          menu-trigger.flex
            template(#btn='{ open, isOpen }')
              +b.SVG.burger(
                ref='burger'
                @click='open(), checkState()'
                :class='{"is-active": isOpen }'
                viewBox="0 0 100 100"
              )
                +e.PATH.item(
                  d="m 30,33 h 40 c 13.100415,0 14.380204,31.80258 6.899646,33.421777 -24.612039,5.327373 9.016154,-52.337577 -12.75751,-30.563913 l -28.284272,28.284272"
                )
                +e.PATH.item(
                  d="m 70,50 c 0,0 -32.213436,0 -40,0 -7.786564,0 -6.428571,-4.640244 -6.428571,-8.571429 0,-5.895471 6.073743,-11.783399 12.286435,-5.570707 6.212692,6.212692 28.284272,28.284272 28.284272,28.284272"
                )
                +e.PATH.item(
                  d="m 69.575405,67.073826 h -40 c -13.100415,0 -14.380204,-31.80258 -6.899646,-33.421777 24.612039,-5.327373 -9.016154,52.337577 12.75751,30.563913 l 28.284272,-28.284272"
                )
            template(#sidebar='{ close: closeSidebar }')
              +b.wrapper-sidebar
                +b.sidebar-currency(
                  v-if='auth && wallets'
                )
                  +e.item.ma--b-xs.ma--t-sm
                    row(
                      :align="['center']"
                      :justify="['between']"
                    )
                      cell
                        row
                          cell
                            +b.invoice
                              +e.image.IMG(
                                src='/static/img/purse.png'
                              )
                          cell
                            +b.P.text.--color-blue-grey.--size-2xxs.--weight-bold {{ _('Основний рахунок') }}
                            +b.P.text.--color-white.--size-2sm.--weight-bold {{ mainAcc.displayedValue }}
                      cell
                        modal-trigger(
                          name='topUpModal'
                          :url="account.verificationRequiredForReplenishment && account.verified !== 1 ? 'modals/NeedVerification' : 'modals/TopUpModal'"
                          :classes="account.verificationRequiredForReplenishment && account.verified !== 1 ? ['vm--modal_2sm'] : ['vm--modal_warning']"
                          :wallet='mainAcc.wallet'
                        )
                          template(#default='{ open }')
                            +b.header-add(
                              @click.prevent='open'
                            )
                              +b.P.text.--size-lg +
                  +e.item.ma--b-xs
                    row(
                      :align="['center']"
                      :justify="['between']"
                    )
                      cell
                        row
                          cell
                            +b.invoice
                              +e.image.IMG(
                                src='/static/img/user+.png'
                              )
                          cell
                            +b.P.text.--color-blue-grey.--size-2xxs.--weight-bold {{ _('Реферальний рахунок') }}
                            +b.P.text.--color-white.--size-2sm.--weight-bold {{ referralAcc.displayedValue }}
                      cell
                        +b.BUTTON.header-add.ds-link(
                          :disabled="!(Number(referralAcc.walle && referralAcc.wallet.amount || 0))"
                          @click="confirmTransaction(referralAcc.wallet)"
                        )
                          +b.P.icon-arrow.text.--size-2xxs
                  +e.item
                    row(
                      :align="['center']"
                      :justify="['between']"
                    )
                      cell
                        row
                          cell
                            +b.invoice
                              +e.image.IMG(
                                src='/static/img/percent.png'
                              )
                          cell
                            +b.P.text.--color-blue-grey.--size-2xxs.--weight-bold {{ _('Кешбек') }}
                            +b.P.text.--color-white.--size-2sm.--weight-bold {{ cashbackAcc.displayedValue }}
                      cell
                        +b.BUTTON.header-add.ds-link(
                          :disabled="!(Number(cashbackAcc.wallet && cashbackAcc.wallet.amount || 0))"
                          @click="confirmTransaction(cashbackAcc.wallet)"
                        )
                          +b.P.icon-arrow.text.--size-2xxs
                +b.sidebar-content.ma--t-4xl
                  row.ma--b-3xl
                    cell(
                      v-if='selects'
                    )
                      d-control-multiselect.language-multiselect(
                        :options="selects.languages"
                        :allow-empty="false"
                        :searchable="false"
                        label='value'
                        v-model='currentLanguage'
                        @input='setLang($event.value)'
                      )
                  +e.UL.menu(
                    v-if="!isAuth"
                  )
                    +e.LI.menu-item.ma--b-sm(
                      v-for='item, index in headerLinks'
                      :key='index'
                    )
                      +b.A.header-link.text.--size-xs(
                        v-if="item.anchor"
                        @click='e => { onMenuLink(e); closeSidebar(); }'
                        :data-goto='item.anchor'
                      ) {{ item.name }}
                      +b.ROUTER-LINK.header-link.text.--size-xs(
                        v-else
                        @click='e => { closeSidebar(); }'
                        :to="{ path: item.to}"
                      ) {{ item.name }}

                  +e.UL.menu(
                    v-if="isAuth"
                  )
                    +e.LI.menu-item.ma--b-sm(
                      v-for='item, index in authLinks'
                      :key='index'
                      @click="closeSidebar"
                    )
                      router-link(
                        :to="item.to"
                      )
                        +b.header-link.text.--size-xs {{ item.name }}
                  +b.P.text.--color-white.--size-sm.--color-blue-grey.ma--t-3xxl {{ _('Ми у соціальних мережах') }}
                  row.ma--t-lg(
                    v-if='organization'
                    :justify="'center'"
                    space='none'
                  )
                    cell(
                      v-for='item, index in organization.socialNetworks'
                      :key='index'
                    )
                      +b.A.sidebar-social(
                        :href='item.link'
                        target="_blank"
                      )
                        +b.IMG(
                          :src='item.icon'
                        )
        cell(
          :cols="['auto', '4-md', '3-lg']"
        )
          router-link(
            :to="{name: LANDING}"
          )
            +b.g-row--justify_center.--aling_center
              +b.IMG.g-cell.g-cols(
                v-if='organization'
                :src='organization.logo'
              )
        cell(
          v-if='!auth'
          :cols="['2', '3-sm', '4-md', '3-lg']"
          :style="{textAlign: 'end'}"
        )
          router-link(
            :to="{name: LOGIN}"
          )
            +b.P.text.--color-white.--size-2lg.icon-contact
        cell(
          v-else
          :cols="['narrow', '5-sm', '4-md', '3-lg']"
        )
          row(
            :align="'center'"
            space="md"
            justify="end"
          )
            cell
              el-notifications(
                :wid='account.wid'
              )
            cell
              userbar(
                :avatar="account.selfie"
                :account="account"
                :wid='account.wid'
              )

</template>

<script>
import ConfirmModal from '@app/modals/ConfirmModal'
import { mapGetters } from 'vuex'
import { prefixLanguage } from '@utils/urls'
import { toBalanceAPI } from '@requests/services/market.service'
import { AUTH_ENDPOINTS } from '@routes/auth/auth'
import { PROFILE_ENDPOINTS } from '@routes/profile/profile'
import { COMMON_ENDPOINTS } from '@routes/commons/commons'
// import { MERCHANT_ENDPOINTS } from '@routes/merchant/merchant'
import { scrollTo } from '@utils/scroll.js'

export default {
  name: 'header-vue',
  data() {
    return {
      isAuth: window.isAuthenticated,
      isSiteMaintenance: prefixLanguage('/maintenance/') === window.location.pathname,
      auth: window.isAuthenticated,
      LANDING: COMMON_ENDPOINTS.LANDING,
      MARKET: COMMON_ENDPOINTS.MARKET,
      ACCOUNTS: PROFILE_ENDPOINTS.ACCOUNTS,
      LOGIN: AUTH_ENDPOINTS.LOGIN,
      REGISTRATION: AUTH_ENDPOINTS.REGISTRATION,
      walletsLinks: [
        PROFILE_ENDPOINTS.ACCOUNTS,
        PROFILE_ENDPOINTS.REFERRALS,
        PROFILE_ENDPOINTS.CASHBACK,
      ],
      headerLinks: [
        {
          name: this._('Криптовалюта'),
          anchor: 'currency',
        },
        {
          name: this._('Поповнити рахунок'),
          anchor: 'topup',
        },
        {
          name: this._('FAQ'),
          anchor: 'faq',
        },
        {
          name: this._('Про нас'),
          anchor: 'about',
        },
        {
          name: this._('Готівка'),
          to: prefixLanguage('/cash/'),
        },
      ],
    }
  },
  computed: {
    ...mapGetters('aliases', {
      aliases: 'getAliases',
    }),
    ...mapGetters('organization', {
      organization: 'getOrganization',
    }),
    ...mapGetters('selects', {
      selects: 'getSelects',
      singleSelect: 'getSingleSelect',
    }),
    ...mapGetters('account', {
      account: 'getAccount',
    }),
    ...mapGetters('account', {
      wallets: 'getWallets',
    }),
    isPartner() {
      return this.account.isPartner
    },
    isCabinet() {
      return this.$route.path.includes('cabinet')
    },
    authLinks() {
      return this.isPartner ? [
        {
          name: this._('Мої рахунки'),
          to: { name: PROFILE_ENDPOINTS.ACCOUNTS },
        },
        // {
        //   name: this._('Мої компанії'),
        //   to: { name: MERCHANT_ENDPOINTS.LIST },
        // },
        {
          name: this._('Ринок'),
          to: { name: COMMON_ENDPOINTS.MARKET },
        },
        {
          name: this._('Обмін'),
          to: { name: COMMON_ENDPOINTS.EXCHANGE },
        },
        {
          name: this._('Готівка'),
          to: { path: prefixLanguage('/cash/') },
        },
      ] : [
        {
          name: this._('Мої рахунки'),
          to: { name: PROFILE_ENDPOINTS.ACCOUNTS },
        },
        {
          name: this._('Ринок'),
          to: { name: COMMON_ENDPOINTS.MARKET },
        },
        {
          name: this._('Обмін'),
          to: { name: COMMON_ENDPOINTS.EXCHANGE },
        },
        {
          name: this._('Готівка'),
          to: { path: prefixLanguage('/cash/') },
        },
      ]
    },
    mainAcc() {
      const main = (this.wallets || []).find(el => el.isMain)
      const data = {
        wallet: main,
        displayedValue: `${Number(main?.amount || 0).toLocaleString('en-US')} ${main?.currency}`,
      }
      return data
    },
    referralAcc() {
      const referral = (this.wallets || []).find(wallet => this.aliases.some(
        alias => wallet.alias === alias.id && 'referral' === alias.slug
      ))
      const data = {
        wallet: referral || {},
        displayedValue: `${Number(referral?.amount || 0).toLocaleString('en-US')} ${referral?.currency || ''}`,
      }
      return data
    },
    cashbackAcc() {
      const cashback = (this.wallets || []).find(wallet => this.aliases.some(alias => wallet.alias === alias.id && 'cashback' === alias.slug))
      const data = {
        wallet: cashback,
        displayedValue: `${Number(cashback?.amount || 0).toLocaleString('en-US')} ${cashback?.currency || ''}`,
      }
      return data
    },
    currentLanguage: {
      get() {
        let lang = {}
        if (this.selects) {
          lang = this.singleSelect('languages', window.language)
        }
        return lang
      },
      set: nVal => nVal,
    },
  },
  methods: {
    onMenuLink(e) {
      const menuLink = e.target
      const gotoBlock = document.getElementById(menuLink.dataset.goto)
      if (gotoBlock) {
        scrollTo(gotoBlock)
        e.preventDefault()
      } else {
        window.location.assign(
          prefixLanguage(`/#${menuLink.dataset.goto}`, { language: window.language })
        )
      }
    },
    setLang(langCode) {
      const url = window.location.href;
      document.cookie = `CHOSEN_LANGUAGE=${langCode}; path=/;`;
      window.location.assign(decodeURIComponent(prefixLanguage(url, { language: langCode })));
    },
    checkState() {
      if (!this.$refs.burger.classList.contains('is-active')) {
        this.$refs.header.style.background = '#02131c'
      } else {
        this.$refs.header.style.background = ''
      }
    },
    confirmTransaction(fromWallet) {
      const pr = arg => new Promise(resolve => {
        if (arg) {
          resolve(arg)
        }
      }).then(() => {
        const data = {
          walletFrom: fromWallet.code,
          amount: fromWallet.amount,
        }
        toBalanceAPI.execute({}, data).then(() => {
        })
      })
      this.$modal.show(ConfirmModal, {
        promise: pr,
        confirmMessages: {
          title: this._('Confirmation transfer title'),
          text: this._('Confirmation transfer text'),
        },
      })
    },
  },
}
</script>
