const IArrowLeftRight = () => import('./IArrowLeftRight')
const ICheckSquare = () => import('./ICheckSquare')
const IListChecks = () => import('./IListChecks')
const ISettingsRotate = () => import('./ISettingsRotate')
const IWallet = () => import('./IWallet')
const ICardTick = () => import('./ICardTick')
const ISecurityCard = () => import('./ISecurityCard')
const IReceiptItem = () => import('./IReceiptItem')
const IBitcoinCard = () => import('./IBitcoinCard')
const IArrowLeft = () => import('./IArrowLeft')
const IMastercard = () => import('./IMastercard')
const IVisa = () => import('./IVisa')
const ICreditCard = () => import('./ICreditCard')
const IAccount = () => import('./IAccount')
const IShield = () => import('./IShield')
const IListMinus = () => import('./IListMinus')
const IBell = () => import('./IBell')
const ISliders = () => import('./ISliders')
const IPercent = () => import('./IPercent')
const IUserPlus = () => import('./IUserPlus')
const ILogOut = () => import('./ILogOut')
const ICash = () => import('./ICash')
const IQuestion = () => import('./IQuestion')

export default function install(Vue) {
  Vue.component('IArrowLeftRight', IArrowLeftRight)
  Vue.component('ICheckSquare', ICheckSquare)
  Vue.component('IListChecks', IListChecks)
  Vue.component('ISettingsRotate', ISettingsRotate)
  Vue.component('IWallet', IWallet)
  Vue.component('ICardTick', ICardTick)
  Vue.component('ISecurityCard', ISecurityCard)
  Vue.component('IReceiptItem', IReceiptItem)
  Vue.component('IBitcoinCard', IBitcoinCard)
  Vue.component('IArrowLeft', IArrowLeft)
  Vue.component('IMastercard', IMastercard)
  Vue.component('IVisa', IVisa)
  Vue.component('ICreditCard', ICreditCard)
  Vue.component('IAccount', IAccount)
  Vue.component('IShield', IShield)
  Vue.component('IListMinus', IListMinus)
  Vue.component('IBell', IBell)
  Vue.component('ISliders', ISliders)
  Vue.component('IPercent', IPercent)
  Vue.component('IUserPlus', IUserPlus)
  Vue.component('ILogOut', ILogOut)
  Vue.component('ICash', ICash)
  Vue.component('IQuestion', IQuestion)
}
