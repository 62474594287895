<template lang="pug">
include ../Bemto
+b.exchange-modal
  +b.close-btn(
    @click.prevent='$emit("close")'
  )
    +e.cross &#10005;
  +b.P.text.--weight-bold.--size-lg.--size-2lg-md {{ _('Переказ між аккаунтами') }}
  +b.VALIDATION-OBSERVER.auth-form.relative(
    ref='validator'
    v-slot="{ valid }"
    tag='form'
  )
    ui-loading(
      v-if="isLoad"
      size="2xl"
    )
    +b.exchange-form
      d-control-input(
        :input-label="_('Номер рахунку користувача')"
        v-mask="['##########']"
        rules='required|length:10'
        vid="walletTo"
        type="number"
        min="1"
        v-model='form.walletTo'
      )
      +b.P.text.--color-red.--size-3xxs.--align-start(
        v-for="item, index in nonFieldErrors"
      ) {{ item.message }}
      //- d-control-multiselect.ma--b-xs(
      //-   :input-label="_('Номер Вашого рахунку')"
      //-   :allow-empty="false"
      //-   :searchable='true'
      //-   :options="wallets || []",
      //-   rules='required'
      //-   label='code'
      //-   v-model='form.walletFrom'
      //- )
      d-control-input.ma--t-xs.ma--b-xs(
        :input-label="_('Сума переказу') + ' ('+ currentCurrency + ')'"
        rules=`required`
        v-model='form.amount'
      )
      +b.ma--b-lg.ma--t-2xs
        +b.SPAN.text.--color-blue-grey.--size-3xxs {{ _('Комісія сайту') + ' ' + paymentConfig.siteCommission + '%' }}
        +b.SPAN.text.--size-xs.ma--l-sm {{ (commission || '') + ' ' + currentCurrency }}
      d-control-input(
        :input-label="_('Загальна сума') + ' ('+ currentCurrency + ')'"
        :disabled='true'
        v-model='total'
      )
      +b.P.text.--color-red.--size-3xxs.--align-center.ma--t-xs(
        v-if='Number(total) > Number(mainWallet.amount)'
      ) {{ _('На вашем счету нехватает средств') }}
    +b.BUTTON.exchange-btn.el-btn.--default.pa--t-xs.pa--b-xs(
      @click.prevent="prepareData(valid)"
      type="submit"
      :disabled='isLoad || Number(form.amount) === 0 || Number(total) > Number(mainWallet.amount)'
      :class="(isLoad || Number(form.amount) === 0 || Number(total) > Number(mainWallet.amount)) && 'is-disabled'"
    )
      +b.P.text.--weight-black.--size-2sm.--align-center {{ _('Перевести') }}
</template>

<script>

import { mask } from 'vue-the-mask'
import { mapGetters, mapActions } from 'vuex'
import {
  transferAPI,
  transferCommissionAPI,
} from '@requests/services/market.service'
import FormMixin, { defaultValidatorErrorsParser } from '@app/Forms/FormMixin'
import { DEFAULT_CURRENCY } from '@/consts'

const TransferAccessModal = () => import('@app/modals/TransferAccessModal')

export default {
  name: 'transfer-modal',
  mixins: [
    FormMixin,
  ],
  directives: {
    mask,
  },
  data() {
    return {
      currentCurrency: DEFAULT_CURRENCY,
      nonFieldErrors: [],
      paymentConfig: {},
      form: {
        walletTo: '',
        amount: '',
        // walletFrom: '',
      },
    }
  },
  computed: {
    ...mapGetters('account', {
      wallets: 'getWallets',
      userConfiguration: 'getAccountConfiguration',
    }),
    ...mapGetters('selects', {
      getSingleSelect: 'getSingleSelect',
    }),
    total() {
      const amount = Number(this.form.amount)
      const percent = amount / 100 * this.paymentConfig.siteCommission
      const total = amount + percent
      return total.toFixed(2)
    },
    commission() {
      const com = Number(this.total) - Number(this.form.amount)
      return com.toFixed(2)
    },
    mainWallet() {
      return this.wallets.find(el => el.isMain)
    },
  },
  mounted() {
    this.getConfig()
  },
  methods: {
    ...mapActions('account',
      ['accountRequest']),
    getConfig() {
      transferCommissionAPI.execute().then(({ data: { item } }) => {
        this.paymentConfig = item
      })
    },
    updateValidator(errors) {
      this.nonFieldErrors = errors.nonFieldErrors
      const parsed = defaultValidatorErrorsParser(errors)
      this.$refs.validator.setErrors(parsed)
    },
    async prepareData(valid) {
      this.$refs.validator.validate();
      if (!valid) return;
      this.submit(valid, this.form);
    },
    send(data) {
      // data.walletFrom = data.walletFrom.code
      return transferAPI.execute({}, data)
        .then(() => {
          this.accountRequest()
          this.$emit('close')
          this.$modal.show(TransferAccessModal, {}, {
            height: 'auto',
            adaptive: true,
          })
        })
    },
  },
}
</script>
