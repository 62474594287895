<template lang="pug">
include ../Bemto
+b.landing-wrapper
  intro-block(
    :introData="introData"
  )
  currency-course
  steps-wrapper(
    :howItsWork='landing.howItWorks'
    :depositDescription='landing.depositDescription'
  )
  //- merchant-block(
  //-   :link="landing.linkToPartnershipDetailPage"
  //-   :content="landing.partnershipDescription"
  //- )
  question-block(
    :faqs='landing.faqs'
  )
  advantage-wrapper(
    :advantages='landing.advantages'
  )
  about-wrapper(
    :aboutDescription='landing.about'
    :statistics='landing.statistics'
  )
</template>

<script>
import { landingData } from '@requests/services/services'
import { PROFILE_ENDPOINTS } from '@routes/profile/profile'
import { scrollTo } from '@utils/scroll.js'

const IntroBlock = () => import('@app/blocks/Intro.vue')
const CurrencyCourse = () => import('@app/blocks/CurrencyCourse.vue')
const StepsWrapper = () => import('@app/blocks/StepsWrapper.vue')
const MerchantBlock = () => import('@app/blocks/MerchantBlock.vue')
const QuestionBlock = () => import('@app/blocks/QuestionBlock.vue')
const AdvantageWrapper = () => import('@app/blocks/AdvantageWrapper.vue')
const AboutWrapper = () => import('@app/blocks/AboutWrapper.vue')

export default {
  name: 'landing',
  components: {
    IntroBlock,
    CurrencyCourse,
    StepsWrapper,
    MerchantBlock,
    QuestionBlock,
    AdvantageWrapper,
    AboutWrapper,
  },
  data() {
    return {
      auth: window.isAuthenticated,
      ACCOUNTS: PROFILE_ENDPOINTS.ACCOUNTS,
      landing: {},
      intro: {},
    }
  },
  async created() {
    await this.getLandingData()
  },
  mounted() {
    this.$nextTick(() => {
      this.initialScrollArchon()
    })
  },
  methods: {
    initialScrollArchon() {
      if (window.location.hash) {
        setTimeout(() => {
          const gotoBlock = document.getElementById(window.location.hash.split('#').join(''))
          if (gotoBlock) {
            scrollTo(gotoBlock)
          }
        }, 800)
      }
    },
    getLandingData() {
      return landingData.execute({}).then(({ data: { item } }) => {
        this.landing = item
      })
    },
  },
  computed: {
    introData() {
      const { title, subtitle } = this.landing
      return { title, subtitle }
    },
  },
}
</script>
