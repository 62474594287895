import {
  getWalletsAPI,
  // walletsAPI,
} from '@requests/services/market.service'
import {
  getAccountConfigurationAPI,
  getAccountAPI,
  removeIpAPI,
  configurationIpAPI,
  patchAccountAPI,
} from '@requests/services/account.service'

export const account = {
  namespaced: true,
  state: {
    account: {},
    accountConfiguration: {},
    wallets: null,
  },
  getters: {
    getAccount: state => state?.account,
    getAccountConfiguration: state => state?.accountConfiguration,
    getWallets: state => state?.wallets,
  },
  actions: {
    async accountRequest({ commit }) {
      if (window.isAuthenticated) {
        await getAccountAPI.execute({ uuid: window.USER_UUID }).then(({ data: { item } }) => {
          commit('setAccount', item)
        })
        await getAccountConfigurationAPI.execute().then(({ data: { item } }) => {
          commit('setAccountConfiguration', item)
        })
        await getWalletsAPI.execute().then(({ data: { item } }) => {
          commit('setWallets', item)
        })
      }
    },
    // createWallet({ commit }) {
    //   walletsAPI.execute().then(({ data: { item } }) => {
    //     commit('setWallets', item)
    //   })
    // },
    patchConfiguration({ commit }, payload) {
      commit('setAccountConfiguration', payload)
    },
    patchAccount({ commit }, payload) {
      patchAccountAPI.execute({ uuid: window.USER_UUID }, payload).then(({ data: { item } }) => {
        commit('setAccount', item)
      })
    },
    addIp({ commit }, payload) {
      configurationIpAPI.execute({}, payload).then(({ data: { item } }) => {
        commit('addIpToList', item)
      })
    },
    removeIp({ commit }, payload) {
      removeIpAPI.execute({ id: payload })
      commit('removeIpFromList', payload)
    },
  },
  mutations: {
    /* eslint-disable no-return-assign */
    setAccount: (state, response) => state.account = response,
    setAccountConfiguration: (state, response) => state.accountConfiguration = response,
    setWallets: (state, response) => state.wallets = response,
    addIpToList: (state, response) => { state.accountConfiguration.ipWhitelist.push(response) },
    removeIpFromList: (state, response) => {
      state.accountConfiguration.ipWhitelist.splice(state.accountConfiguration.ipWhitelist.findIndex(l => l.id === response), 1)
    },
  },
}
