<template lang="pug">
include ../Bemto
+b.reset-modal.profile-modal
  +b.close-btn(
    @click.prevent='closeHandler'
  )
    +e.cross &#10005;
  +b.P.profile-modal__title.text.--weight-bold.--size-lg.--size-2lg-sm.--align-center {{ info.text }}
  +b.P.profile-modal__title.text.--size-sm.--align-center(
    v-if="info.hint"
  ) {{ info.hint }}
  +e.BUTTON.continue-btn.el-btn.--default.ma--t-sm(
    @click="closeHandler"
  )
    +b.P.text.--weight-black.--size-2sm {{ _('OK') }}
</template>

<script>
import { PROFILE_ENDPOINTS } from '@routes/profile/profile'

export default {
  name: 'verification-success',
  props: {
    info: {},
    isRequiredRedirect: {
      type: Boolean,
      default: true,
    },
  },
  mounted() {
    this.closeTimer()
  },
  methods: {
    closeTimer() {
      setTimeout(this.closeHandler, 5000)
    },
    closeHandler() {
      this.$emit('close')
      if (this.isRequiredRedirect) {
        this.$router.push({ name: PROFILE_ENDPOINTS.SETTINGS })
      }
    },
  },
}
</script>
