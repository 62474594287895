const CurrencyCard = () => import('./CurrencyCard.vue')
const CashCurrencyCard = () => import('./CashCurrencyCard.vue')
const MainStepCard = () => import('./MainStepCard.vue')
const CashStepCard = () => import('./CashStepCard.vue')
const AdvantageCard = () => import('./AdvantageCard.vue')
const AboutCard = () => import('./AboutCard.vue')
const AccountCard = () => import('./AccountCard.vue')
const ReferralCard = () => import('./ReferralCard.vue')
const ExchangeCard = () => import('./ExchangeCard.vue')
const ProfileCard = () => import('./ProfileCard.vue')

export default function install(Vue) {
  Vue.component('currency-card', CurrencyCard)
  Vue.component('cash-currency-card', CashCurrencyCard)
  Vue.component('main-step-card', MainStepCard)
  Vue.component('cash-step-card', CashStepCard)
  Vue.component('advantage-card', AdvantageCard)
  Vue.component('about-card', AboutCard)
  Vue.component('account-card', AccountCard)
  Vue.component('referral-card', ReferralCard)
  Vue.component('exchange-card', ExchangeCard)
  Vue.component('profile-card', ProfileCard)
}
