<template lang="pug">
include ../Bemto
+b.radio-block
  slot
  +e.P.error(
    v-if='errorText && !value'
  ) {{ errorText }}
  row(
    :align="'center'"
    space='sm'
    appearance="spaced"
  )
    //- :justify="['between']"
    cell(
      v-for="(item, index) in radioData"
      :key='index'
      :cols="[]"
    ): .wrapper-radio-cell(
      :class="{'selected' : item.name === value}"
    )
      row(
        :align="['center']"
        space='sm'
      )
        cell(:cols="[]")
          +b-context('radio-block')
            +e.INPUT.hidden-radio(
              type='radio'
              v-model='value'
              :value='item.name'
              :name='name'
              @change="$emit('radio-value', value)"
              :id="name + index"
              :disabled="disabled"
            )
            +e.LABEL.fake-radio(
              :for="name + index"
            )
        cell(:cols="[]")
          +b-context('radio-block')
            +e.LABEL.radio-label(
              :for="name + index"
            ).text.--size-xs(
                :class="{'text--color-turquoise': value === item.name}"
              ) {{ item.name }}
</template>

<script>
export default {
  name: 'radio-bar',
  props: {
    setValue: {},
    errorText: String,
    name: String,
    radioData: Array,
    disabled: {
      type: Boolean,
      default: false,
    },
  },
  data() {
    return {
      value: null,
    }
  },
  mounted() {
    if (undefined !== this.setValue) {
      this.value = this.setValue
    }
  },
  watch: {
    setValue() {
      this.value = this.setValue
    },
  },
}
</script>
