<template lang="pug">
include ../Bemto
+b.auth-warning-modal
  +b.close-btn(
    @click.prevent='$emit("close")'
  )
    +e.cross &#10005;
  +b.P.text.--weight-bold.--size-lg.--size-2lg-md {{ _('Необхідна авторизація') }}
  +b.P.text.--weight-medium.--size-3xxs.--size-sm-md.--color-blue-grey.--lh-4lg.ma--t-sm {{ _('Увійдіть в обліковий запис або створіть новий для використання всіх можливостей платформи') }}
  router-link(
    :to="{name: 'login'}"
  )
    +b.modal-login.el-btn.--default(
      @click='$emit("close")'
    )
      +b.P.text.--weight-black.--size-2sm {{ _('Увійти') }}
</template>

<script>
export default {
  name: 'auth-modal',
}
</script>
