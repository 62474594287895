<template lang="pug">
include ../Bemto
+b.reset-modal.profile-modal
  +b.close-btn(
    @click.prevent='$emit("close")'
  )
    +e.cross &#10005;
  +e.P.title.text.--weight-bold.--size-lg.--size-2lg-sm.--align-center {{ _('Ви замовили відновлення пароля') }}
  +b.P.title.text.--weight-medium.--size-3xxs.--size-sm-md.--color-blue-grey.--align-center.--lh-4lg.ma--t-sm {{ _('Вам було надіслано повідомлення з кодом') }}
  +e.BUTTON.continue-btn.el-btn.--default.ma--t-sm(
    @click='continueReset'
  )
    +b.P.text.--weight-black.--size-2sm {{ _('Continue') }}
</template>

<script>

import ContinueReset from '@app/modals/ContinueReset'

export default {
  name: 'restore-success',
  methods: {
    continueReset() {
      this.$emit('close')
      this.$modal.show(ContinueReset,
        {
          info: this.$attrs,
        },
        {
          height: 'auto',
          classes: ['defaultModal', 'vm--modal_sm'],
        })
    },
  },
}
</script>
