<template lang="pug">
  include ../Bemto
  ui-click-outside(:do='hideBar')
    div
      slot(
        name='btn',
        :open='() => showSidebar()',
        :is-open='show'
      )
      .is-blured(
        v-if='show'
        @click.prevent='showSidebar()'
      )
      transition(name='slideInLeft')
        +b#side-menu.side-menu(
          v-show='show'
        )
          slot(
            :close='() => show = false'
            name='sidebar'
          )
</template>

<script>
export default {
  name: 'MenuTrigger',
  data() {
    return {
      show: false,
    }
  },
  watch: {
    $route: {
      handler() {
        this.hideBar();
      },
    },
  },
  methods: {
    showSidebar() {
      this.show = !this.show
    },
    hideBar() {
      this.show = false
    },
  },
}
</script>
