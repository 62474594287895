import Icons from './icons'

const Loading = () => import('./Loading.vue')
const UiToggler = () => import('./UiToggler.vue')
const MerchantLanguages = () => import('./MerchantLanguages.vue')

export default function install(Vue) {
  Vue.use(Icons)
  Vue.component('ui-loading', Loading)
  Vue.component('ui-toggler', UiToggler)
  Vue.component('merchant-languages', MerchantLanguages)
}
