<template lang="pug">
include ../Bemto
+b.profile-modal
  +b.close-btn(
    @click.prevent='$emit("close")'
  )
    +e.cross &#10005;
  code-confirm(
    :loading="isLoad"
    @confirm='configurationHandler'
    @repeat='repeatCode'
  )
    +b.text--size-sm.--align-center(
      v-if="target"
    ) {{ _('The code was sent to the') + " " + target }}
</template>

<script>

import {
  changeEmailVerifyAPI,
  changePhoneVerifyAPI,
  changeEmailAPI,
  changePhoneAPI,
} from '@requests/services/account.service'
import ChangePasswordSuccess from '@app/modals/ChangePasswordSuccess'
import { mapActions } from 'vuex'

export default {
  name: 'code-modal',
  props: {
    info: {},
  },
  data() {
    return {
      isLoad: false,
    }
  },
  computed: {
    target() {
      return this.info?.modalData?.params?.phone || this.info?.modalData?.params?.email
    },
  },
  methods: {
    ...mapActions('account', ['patchAccount']),
    configurationHandler(code) {
      this.isLoad = true
      if ('email' === this.info.modalData.type) {
        this.emailVerify(code)
      } else if ('phone' === this.info.modalData.type) {
        this.phoneVerify(code)
      }
    },
    repeatCode() {
      if ('email' === this.info.modalData.type) {
        changeEmailAPI.execute({}, this.info.requestData)
      } else if ('phone' === this.info.modalData.type) {
        changePhoneAPI.execute({}, this.info.requestData)
      }
    },
    emailVerify(code) {
      const data = this.info.modalData.params
      data.code = code
      changeEmailVerifyAPI.execute({}, data).then(({ data: { item } }) => {
        const patchData = item
        this.patchAccount(patchData)
        this.$emit('close')
        this.$modal.show(ChangePasswordSuccess, {
          info: {
            title: this._('Email successfully changed'),
          },
        },
        {
          classes: ['defaultModal', 'vm--modal_sm'],
        })
      })
        .finally(() => {
          this.isLoad = false
        })
    },
    phoneVerify(code) {
      const data = this.info.modalData.params
      data.code = code
      changePhoneVerifyAPI.execute({}, data).then(({ data: { item } }) => {
        const patchData = item
        this.patchAccount(patchData)
        this.$emit('close')
        this.$modal.show(ChangePasswordSuccess, {
          info: {
            title: this._('Phone successfully changed'),
          },
        },
        {
          classes: ['defaultModal', 'vm--modal_sm'],
        })
      })
        .finally(() => {
          this.isLoad = false
        })
    },
  },
}
</script>
