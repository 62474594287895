const PersonalData = () => import('./PersonalData.vue')
const Address = () => import('./Address.vue')
const Documents = () => import('./Documents.vue')
const Photo = () => import('./Photo.vue')

export default function install(Vue) {
  Vue.component('personal-data', PersonalData)
  Vue.component('address-data', Address)
  Vue.component('documents-data', Documents)
  Vue.component('photo-data', Photo)
}
