<template lang="pug">
include ../Bemto
+b.alert-checkbox
  +e.hidden.INPUT(
    type='checkbox'
    :name='name'
    :id='name'
    :true-value="trueValue"
    :false-value="falseValue"
    v-model='value'
    @change="$emit('checkbox-data', value)"
  )
  +e.label.LABEL(
    :for='name'
  )
    slot
  +e.wrapper
    +e.trigger.LABEL(
      :for='name'
    )
</template>

<script>
export default {
  name: 'alert-checkbox',
  props: {
    name: String,
    setValue: [Boolean, Number, String],
    trueValue: {
      default: () => true,
    },
    falseValue: {
      default: () => false,
    },
  },
  data() {
    return {
      value: null,
    }
  },
  mounted() {
    this.value = this.setValue
  },
}
</script>
